//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Sync from './Sync.vue';
import Log from './Log.vue';
export default {
  name: 'SyncLPRUS',
  components: {
    Sync: Sync,
    Log: Log
  },
  data: function data() {
    return {
      t: 'sync'
    };
  }
};